import { extendTheme } from '@chakra-ui/react';
import "@fontsource/sawarabi-gothic"

const theme = {
  colors: {
    brand: {
      100: 'cyan',
    },
  },
  components: {
    Text: { baseStyle: { fontSize: 'xs' } },
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
        _hover: { boxShadow: 'none' },
      },
    },
    IconButton: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
      },
    },
    Tab: { baseStyle: { _focus: { boxShadow: 'none' } } },
  },
  styles: {
    global: {
      html: {
        fontSize: '62.5%',
      },
      body: {
        fontSize: '1.4rem',
        fontFamily:
          'Sawarabi Gothic, 游明朝, YuMincho, Hiragino Mincho ProN W3, ヒラギノ明朝 ProN W3, Hiragino Mincho ProN, HG明朝E, ＭＳ Ｐ明朝, ＭＳ 明朝, serif',
        margin: 0,
      },
    },
  },
};

export default extendTheme(theme);
