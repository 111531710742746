import React from 'react';
import { RecoilRoot } from 'recoil';
import { StoreProvider } from './src/context/StoreContext';

import './src/styles/global.css';

export const wrapRootElement = ({ element, props }) => {
  return (
    <RecoilRoot {...props}>
      <StoreProvider>{element}</StoreProvider>
    </RecoilRoot>
  );
};
