// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-shipping-tsx": () => import("./../../../src/pages/about-shipping.tsx" /* webpackChunkName: "component---src-pages-about-shipping-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-law-tsx": () => import("./../../../src/pages/law.tsx" /* webpackChunkName: "component---src-pages-law-tsx" */),
  "component---src-pages-others-tsx": () => import("./../../../src/pages/others.tsx" /* webpackChunkName: "component---src-pages-others-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-watch-deco-tsx": () => import("./../../../src/pages/watch-deco.tsx" /* webpackChunkName: "component---src-pages-watch-deco-tsx" */),
  "component---src-pages-whats-cj-tsx": () => import("./../../../src/pages/whats-cj.tsx" /* webpackChunkName: "component---src-pages-whats-cj-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

