import * as React from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || '',
});

interface SubtotalPriceV2 {
  subtotalPriceV2?: {
    currencyCode: string;
    amount: string;
  };
}

type Checkout = Client.Cart & SubtotalPriceV2;

const defaultValues: {
  cart: [];
  isOpen: boolean;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  addVariantToCart:
    | ((variantId: string, quantity: string) => Promise<void>)
    | (() => void);
  removeLineItem:
    | ((checkoutID: string, lineItemID: string) => Promise<void>)
    | (() => void);
  updateLineItem:
    | ((
        checkoutID: string,
        lineItemID: string,
        quantity: string
      ) => Promise<void>)
    | (() => void);
  client: any;
  checkout: Checkout;
  didJustAddToCart?: boolean;
} = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
    webUrl: '',
    checkoutUrl: '',
    id: '',
    lineItemCount: 0,
    subtotalPrice: '0',
    completedAt: '',
    subtotalPriceV2: {
      currencyCode: '',
      amount: '0',
    },
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }: any) => {
  const [checkout, setCheckout] = React.useState<Checkout>(
    defaultValues.checkout
  );
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] =
    React.useState<boolean>(false);

  const setCheckoutItem = (checkout: Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id as string);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = (await client.checkout.fetch(
            existingCheckoutID
          )) as Checkout;
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, '');
        }
      }

      const newCheckout = (await client.checkout.create()) as Checkout;
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId: string, quantity: string) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
